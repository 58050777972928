<template>
  <div>
    <feed-list-edit
      :key="JSON.stringify(selectedFeed)"
      :is-edit-feed-sidebar-active.sync="isEditFeedSidebarActive"
      :feed.sync="selectedFeed"
      @refetch-data="fetchFeeds(selectedAnimal.id)"
    />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0 p-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t("eachPage") }}</label>
            <v-select
              v-model="perPage"
              transition=""
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label> {{ $t("piece") }} </label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end" />
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refFeedListTable"
        :responsive="true"
        :items="filteredFeeds"
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :per-page="perPage"
        :current-page.sync="currentPage"
      >
        <!-- Column: Feed -->
        <template #cell(feedid)="data">
          <b-link @click="openEditSideBar(data)">
            <span class="align-middle ml-50 text-secondary">
              {{
                feedes.find((x) => x.id == data.item.feedid)
                  ? feedes.find((x) => x.id == data.item.feedid).name
                  : ""
              }}
            </span>
          </b-link>
        </template>

        <template #cell(delete)="data">
          <b-link
            @click="
              deleteFeed({
                animalid: selectedAnimal.id,
                feedid: data.item.id,
              })
            "
          >
            <div class="d-flex">
              <feather-icon icon="TrashIcon" />
              <small class="align-middle ml-50 text-dark">{{
                $t("delete")
              }}</small>
            </div>
          </b-link>
        </template>

        <template #cell(edit)="data">
          <b-link @click="openEditSideBar(data)">
            <div class="d-flex">
              <feather-icon icon="EditIcon" />
              <small class="align-middle ml-50 text-dark">{{
                $t("edit")
              }}</small>
            </div>
          </b-link>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              <span class="text-muted"
                >{{ $t("showing") }} {{ dataMeta.from }} {{ $t("to") }}
                {{ dataMeta.to }} {{ $t("of") }} {{ dataMeta.of }}
                {{ $t("entries") }} </span
              ></span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="filteredFeeds.length"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BTable, BLink, BPagination } from "bootstrap-vue";
import { getCurrentInstance, ref } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useFeedList from "./useFeedYogunList";
import FeedListEdit from "./AnimalListEditFeedingYogun.vue";
import vSelect from "vue-select";
import { getUserData } from "@/auth/utils";
import VueI18n from "@/libs/i18n";

export default {
  components: {
    FeedListEdit,
    BCard,
    BRow,
    BCol,

    BTable,
    BLink,
    BPagination,
    vSelect,
  },
  props: {
    selectedAnimal: {
      type: Object,
      required: true,
    },
  },
  mounted() {},

  beforeRouteEnter(to, from, next) {
    if (to.name == "feeds") {
      to.meta.breadcrumb = [];

      to.meta.breadcrumb.push({
        text: VueI18n.t("feeds"),
        active: true,
      });
    }
    next();
  },
  setup(props, { emit }) {
    const isAddNewFeedSidebarActive = ref(false);
    const isEditFeedSidebarActive = ref(false);
    const selectedFeed = ref({});
    const feedData = getUserData();
    const openEditSideBar = ({ item }) => {
      selectedFeed.value = item;
      isEditFeedSidebarActive.value = !isEditFeedSidebarActive.value;
    };
    const vm = getCurrentInstance();
    const feedes = ref([]);

    const {
      fetchFeeds,
      tableColumns,
      perPage,
      currentPage,
      totalFeeds,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refFeedListTable,
      refetchData,
      allFeeds,
      filteredFeeds,
      // UI
      perPageOptions,
      // Extra Filters
      typeFilter,
      genderFilter,
      deleteFeed,
    } = useFeedList();

    fetchFeeds(props.selectedAnimal.id);

    return {
      // Sidebar
      isAddNewFeedSidebarActive,
      isEditFeedSidebarActive,
      openEditSideBar,
      feedes,
      selectedFeed,

      fetchFeeds,
      tableColumns,
      perPage,
      currentPage,
      totalFeeds,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refFeedListTable,
      refetchData,
      perPageOptions,
      // Filter
      avatarText,
      // UI
      allFeeds,
      filteredFeeds,
      // Extra Filters
      typeFilter,
      genderFilter,
      deleteFeed,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
