<template>
  <div>
    <b-modal
      id="eedit-feed-sidebar"
      centered
      :visible="isEditFeedSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      size="lg"
      backdrop
      no-header
      right
      :ok-title="$t('ok')"
      ok-only
      :title="$t('editAnimalFeed')"
      @ok="onSubmit(blankFeedData)"
      @hidden="formValidation(resetblankfeed).resetForm"
      @change="(val) => changed(val)"
    >
      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->

        <b-form
          ref="refForm"
          class="p-2 modal-form"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="formValidation(resetblankfeed).resetForm"
        >
          <button ref="submitButtonRef" type="submit" class="d-none" />

          <!-- feeddate -->
          <validation-provider
            #default="validationContext"
            name="feeddate"
            rules="required"
          >
            <b-form-group :label="$t('feedDateTime')" label-for="feeddate">
              <b-form-datepicker
                v-model="blankFeedData.givendatetime"
                :locale="$i18n.locale"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- productionRation -->
          <validation-provider
            #default="validationContext"
            name="productionRation"
            rules="required"
          >
            <b-form-group
              :label="$t('productionRation')"
              label-for="productionRation"
            >
              <v-select
                v-model="blankFeedData.productionrationid"
                transition=""
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="productionRations"
                class="w-100"
                :reduce="(val) => val.value"
                @input="onSelectProductionRation"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- amount -->
          <validation-provider
            v-if="false"
            #default="validationContext"
            name="amount"
            rules="required"
          >
            <b-form-group :label="$t('amountYogun')" label-for="amount">
              <b-form-input
                id="eamountyogun"
                v-model="blankFeedData.amount"
                autofocus
                :state="
                  formValidation(resetblankfeed).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
                type="number"
                step="0.1"
                disabled
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- cost -->
          <validation-provider
            #default="validationContext"
            name="cost"
            rules="required"
          >
            <b-form-group :label="$t('costYogun')" label-for="cost">
              <b-form-input
                id="ecostyogun"
                v-model="blankFeedData.cost"
                autofocus
                :state="
                  formValidation(resetblankfeed).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
                type="number"
                step="0.1"
                disabled
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormDatepicker,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import vSelect from "vue-select";

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormDatepicker,
    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEditFeedSidebarActive",
    event: "update:is-edit-feed-sidebar-active",
  },
  props: {
    isEditFeedSidebarActive: {
      type: Boolean,
      required: true,
    },
    feed: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      blankFeedData: {
        ...this.feed,
        givendatetime: this.feed.givendate,
        amount: this.feed.givenamount,
        cost: this.feed.cost,
      },
      formValidation: formValidation,
      feedes: [],
      productionRations: [],
    };
  },
  async mounted() {
    this.resetblankfeed();
    let productionRations = [];
    if (store.state.rationsModule.productionRationData?.length > 0) {
      productionRations = store.state.rationsModule.productionRationData;
    } else {
      productionRations = await store.dispatch(
        "rationsModule/fetchProductionRations",
        {
          tur: "",
          plantid: this.$store.state.app.selectedPlantId,
        }
      );
    }
    this.productionRations = productionRations.map((ration) => {
      return {
        value: ration.id,
        label: ration.rasyonadi,
      };
    });
  },
  async created() {},
  methods: {
    async onSelectProductionRation(val) {
      if (val == null) return;
      const selectedRation = await store.dispatch(
        "rationsModule/fetchProductionRation",
        {
          id: val,
          tur: "11",
          plantid: this.$store.state.app.selectedPlantId,
        }
      );
      this.blankFeedData.amount = selectedRation.amountkesif;
      this.blankFeedData.cost = selectedRation.pricekesif;
    },
    async changed(val) {
      this.resetblankfeed();
      this.$emit("update:is-edit-feed-sidebar-active", val);
    },
    onSubmit(feedData) {
      store.dispatch("animalsModule/updateAnimalFeed", feedData).then(() => {
        this.$emit("refetch-data");
        this.$emit("update:is-edit-feed-sidebar-active", false);
      });
    },
    async resetblankfeed() {
      this.blankFeedData = {
        ...this.feed,
        givendatetime: this.feed.givendate,
        amount: this.feed.givenamount,
        cost: this.feed.cost,
      };
      this.onSelectProductionRation(this.blankFeedData.productionrationid);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#edit-feed-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
