<template>
  <div>
    <b-modal
      id="add-new-feed-sidebar"
      centered
      :visible="isAddNewFeedSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      size="lg"
      backdrop
      no-header
      right
      :ok-title="$t('ok')"
      ok-only
      :title="$t('feedAdd')"
      @ok="onSubmit(blankFeed)"
      @hidden="resetForm"
      @change="(val) => $emit('update:is-add-new-feed-sidebar-active', val)"
    >
      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->

        <b-form
          ref="refForm"
          class="p-2 modal-form"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <button ref="submitButtonRef" type="submit" class="d-none" />
          <!-- feeddate -->
          <validation-provider
            #default="validationContext"
            name="feeddate"
            rules="required"
          >
            <b-form-group :label="$t('feedDateTime')" label-for="feeddate">
              <b-form-datepicker
                v-model="blankFeed.givendatetime"
                :locale="$i18n.locale"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- productionRation -->
          <validation-provider
            #default="validationContext"
            name="productionRation"
            rules="required"
          >
            <b-form-group
              :label="$t('productionRation')"
              label-for="productionRation"
            >
              <v-select
                v-model="blankFeed.productionrationid"
                transition=""
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="productionRations"
                class="w-100"
                :reduce="(val) => val.value"
                @input="onSelectProductionRation"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- amount -->
          <validation-provider
            v-if="false"
            #default="validationContext"
            name="amount"
            rules="required"
          >
            <b-form-group :label="$t('amountYogun')" label-for="amount">
              <b-form-input
                id="aamountkaba"
                v-model="blankFeed.amount"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
                type="number"
                disabled
                step="0.1"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- amountKaba -->
          <validation-provider
            v-if="false"
            #default="validationContext"
            name="amountKaba"
            rules="required"
          >
            <b-form-group :label="$t('amountKaba')" label-for="amountKaba">
              <b-form-input
                id="amountKaba"
                v-model="blankFeed.amountkaba"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
                type="number"
                disabled
                step="0.1"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- cost -->
          <validation-provider
            #default="validationContext"
            name="cost"
            rules="required"
          >
            <b-form-group :label="$t('costYogun')" label-for="cost">
              <b-form-input
                id="acost"
                v-model="blankFeed.cost"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
                type="number"
                disabled
                step="0.1"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- costKaba -->
          <validation-provider
            #default="validationContext"
            name="costKaba"
            rules="required"
          >
            <b-form-group :label="$t('costKaba')" label-for="costKaba">
              <b-form-input
                id="costKaba"
                v-model="blankFeed.costkaba"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
                type="number"
                disabled
                step="0.1"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormDatepicker,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref, getCurrentInstance } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { getUserData } from "@/auth/utils";
import vSelect from "vue-select";
export default {
  components: {
    BModal,
    BForm,
    BFormInput,
    BFormGroup,

    BFormInvalidFeedback,
    BFormDatepicker,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewFeedSidebarActive",
    event: "update:is-add-new-feed-sidebar-active",
  },
  props: {
    isAddNewFeedSidebarActive: {
      type: Boolean,
      required: true,
    },
    selectedAnimal: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      productionRations: [],
    };
  },
  async mounted() {
    let productionRations = [];

    productionRations = await store.dispatch(
      "rationsModule/fetchProductionRations",
      {
        tur: "",
        plantid: this.$store.state.app.selectedPlantId,
      }
    );

    this.productionRations = productionRations.map((ration) => {
      return {
        value: ration.id,
        label: ration.rasyonadi,
        tip: ration.tip,
      };
    });
  },
  methods: {
    async onSelectProductionRation(val) {
      if (val == null) return;
      const selectedRation = await store.dispatch(
        "rationsModule/fetchProductionRation",
        {
          id: val,
          tur: "11",
          plantid: this.$store.state.app.selectedPlantId,
        }
      );
      this.blankFeed.amount = selectedRation.amountkesif;
      this.blankFeed.amountkaba = selectedRation.amountkaba;
      this.blankFeed.cost = selectedRation.pricekesif;
      this.blankFeed.costkaba = selectedRation.pricekaba;
    },
  },

  setup(props, { emit, http }) {
    const vm = getCurrentInstance();
    const dayJs = dayjs.extend(relativeTime);
    const feedes = ref([]);

    const blankFeedData = {
      id: 0,
      companyid: getUserData().companyid,
      plantid: store.state.app.selectedPlantId,
      animalid: props.selectedAnimal.id,
      givendatetime: dayJs().format(),
      productionrationid: null,
      amount: "",
      cost: "",
      amountkaba: "",
      costkaba: "",
    };

    const blankFeed = ref(JSON.parse(JSON.stringify(blankFeedData)));
    const resetblankFeed = () => {
      blankFeed.value = JSON.parse(JSON.stringify(blankFeedData));
    };

    const onSubmit = (feed) => {
      store.dispatch("animalsModule/addAnimalFeed", feed).then((res) => {
        if (res.statusdata == "-2") {
          vm.proxy.$toast({
            component: ToastificationContent,
            props: {
              title: vm.proxy.$t("animalAlreadyAdded"),
              icon: "AlertCircleIcon",
              variant: "alert",
            },
          });
        } else if (res.statusdata == "1") {
          vm.proxy.$toast({
            component: ToastificationContent,
            props: {
              title: vm.proxy.$t("animalAdded"),
              icon: "AlertCircleIcon",
              variant: "success",
            },
          });
          emit("refetch-data");
          emit("update:is-add-new-feed-sidebar-active", false);
        } else {
          vm.proxy.$toast({
            component: ToastificationContent,
            props: {
              title: vm.proxy.$t("animalFeedNotAdded"),
              icon: "AlertCircleIcon",
              variant: "success",
            },
          });
        }
      });
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetblankFeed);
    return {
      blankFeed,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      feedes,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-feed-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.sidebar-xl-scoped {
  width: 70rem;
}
</style>
