import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import VueI18n from "@/libs/i18n";

import dayjs from "dayjs";
import "dayjs/locale/tr";
import "dayjs/locale/en";
import "dayjs/locale/ru";
import "dayjs/locale/az";
// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { getCurrentInstance } from "@vue/composition-api";

export default function useFeedList() {
  // Use toast
  const toast = useToast();
  const vm = getCurrentInstance();

  const refFeedListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    {
      key: "givendate",
      label: VueI18n.t("feedDateTime"),
      sortable: false,
      formatter: (val) =>
        `${dayjs(val).locale(`${VueI18n.locale}`).format("DD MMMM YYYY dddd")}`,
    },
    {
      key: "cinsname",
      label: VueI18n.t("feedType"),
      sortable: false,
    },
    {
      key: "givenamount",
      label: VueI18n.t("amount"),
      sortable: false,
    },
    {
      key: "cost",
      label: VueI18n.t("cost"),
      sortable: false,
    },

    {
      key: "productionrationid",
      label: VueI18n.t("productionRation"),
      sortable: false,
    },

    { key: "delete", label: VueI18n.t("delete") },
    { key: "edit", label: VueI18n.t("edit") },
  ];
  const perPage = ref(10);
  const totalFeed = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const roleFilter = ref(null);
  const planFilter = ref(null);
  const feedFilter = ref(null);
  const allFeeds = ref([]);
  const filteredFeeds = ref([]);

  const dataMeta = computed(() => {
    const localItemsCount = filteredFeeds.value.length;
    const to = perPage.value * currentPage.value < localItemsCount;

    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: to
        ? perPage.value * (currentPage.value - 1) + perPage.value
        : localItemsCount,
      of: allFeeds.value.length,
    };
  });

  const refetchData = () => {
    if (searchQuery.value) {
      filteredFeeds.value = allFeeds.value.filter((x) =>
        x.feedname.toLowerCase().includes(searchQuery.value.toLowerCase())
      );
    } else {
      filteredFeeds.value = allFeeds.value;
    }
  };

  watch(
    [currentPage, perPage, searchQuery, roleFilter, planFilter, feedFilter],
    () => {
      refetchData();
    }
  );

  const fetchFeeds = async (ctx) => {
    var feed = await store
      .dispatch("animalsModule/fetchAnimalFeeds", { animalid: ctx, type: 0 })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            text: VueI18n.t("xFetchError", {
              value: VueI18n.t("feeds"),
            }),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    allFeeds.value = feed;
    filteredFeeds.value = [...feed];
    refetchData();

    return feed;
  };

  const deleteFeed = (ctx) => {
    store
      .dispatch("animalsModule/deleteAnimalFeed", { id: ctx.feedid, type: 0 })
      .then((response) => {
        fetchFeeds(ctx.animalid);
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("successful"),
            icon: "CheckIcon",
            variant: "success",
            text: VueI18n.t("deleted", { type: VueI18n.t("feeds") }),
          },
        });
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            text: VueI18n.t("xFetchError", {
              value: VueI18n.t("feeds"),
            }),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  // *===============================================---*
  // *--------------------- UI -------------------------*
  // *===============================================---*

  return {
    fetchFeeds,
    tableColumns,
    perPage,
    currentPage,
    totalFeed,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refFeedListTable,
    deleteFeed,
    filteredFeeds,
    allFeeds,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    feedFilter,
  };
}
