<template>
  <div>
    <portal to="animal-actions-button">
      <b-button
        variant="primary"
        @click="isAddNewFeedSidebarActive = !isAddNewFeedSidebarActive"
      >
        <span class="text-nowrap">{{ $t("feedAdd") }}</span>
      </b-button>
      <feed-list-add-new
        :is-add-new-feed-sidebar-active.sync="isAddNewFeedSidebarActive"
        :selected-animal="selectedAnimal"
        @refetch-data="
          () => {
            $emit('refetch');
            refetch();
          }
        "
      />
    </portal>
    <b-row>
      <b-col class="mb-4 border-bottom">
        <h4>{{ $t("roughage") }}</h4>
        <animal-list-feeding-kaba
          :key="refetchData"
          :selected-animal="selectedAnimal"
          @refetch="refetch"
        />
      </b-col>
      <b-col>
        <h4>{{ $t("grain") }}</h4>
        <animal-list-feeding-yogun
          :key="refetchData"
          :selected-animal="selectedAnimal"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BButton } from "bootstrap-vue";

import VueI18n from "@/libs/i18n";
import AnimalListFeedingKaba from "./AnimalListFeedingKaba.vue";
import AnimalListFeedingYogun from "./AnimalListFeedingYogun.vue";
import FeedListAddNew from "./AnimalListAddFeeding.vue";

export default {
  components: {
    BRow,
    BCol,
    AnimalListFeedingKaba,
    AnimalListFeedingYogun,
    FeedListAddNew,
    BButton,
  },
  props: {
    selectedAnimal: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      refetchData: 0,
      isAddNewFeedSidebarActive: false,
    };
  },
  mounted() {},
  methods: {
    refetch() {
      this.refetchData++;
    },
  },

  beforeRouteEnter(to, from, next) {
    if (to.name == "feeds") {
      to.meta.breadcrumb = [];

      to.meta.breadcrumb.push({
        text: VueI18n.t("feeds"),
        active: true,
      });
    }
    next();
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
