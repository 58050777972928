<template>
  <div>
    <animal-list-feeding
      v-if="animal.id != null"
      :key="animal.id"
      :selected-animal="animal"
    />
  </div>
</template>

<script>
import AnimalListFeeding from "./components/AnimalFeeds/AnimalListFeeding.vue";
import { mapState } from "vuex";
export default {
  components: { AnimalListFeeding },
  computed: {
    ...mapState({
      animal: (state) => state.animalsModule.selectedAnimal || {},
    }),
  },
};
</script>

<style></style>
